import React from "react"
import { Link } from "gatsby"
import HeroImage from "../../../contents/Home/HeroImage"
import SvgDevices from "../../../contents/Home/SvgDevices"
import SvgDeadPixelRepair from "../../../contents/Home/SvgDeadPixelRepair"
import SvgStuckPixelRepair from "../../../contents/Home/SvgStuckPixelRepair"
import SEO from "../../../components/Seo"
const HomePage = () => {
  return (
    <>
      <SEO
        title="Test dei pixel"
        lang="it"
        description="Test per pixel morti, pixel bloccati, riparare i pixel morti e pixel bloccati sui tuoi monitor, display, tablet, computer e TV."
        keywords="Test morto pixel, test di pixel bloccato, riparazione pixel bloccata, riparazione pixel morto, test di pixel incollato, che è il pixel morto, ciò che è il pixel morto, cosa è bloccato pixel, pixel morto, la differenza pixel morta, il pixel morto, il pixel morto, il pixel morto, Pixel morto, test pixel, pixel bloccato, riparazione pixel online"
      />
      <h1 className="pb-0 mb-0 text-center">
        Controlla i pixel <span className="text-indigo-600">morti</span> o{" "}
        <span className="text-red-500">bloccati!</span>
      </h1>

      <HeroImage className="max-w-full px-3 md:px-10" />

      <p className="lead">
        Puoi testare i pixel morti o i pixel bloccati sui telefoni cellulari, i
        tablet, i televisori e i computer tramite il browser e utilizzare lo
        strumento di riparazione pixel gratuito senza alcuna installazione.
      </p>
      <h2>Test morto o bloccato pixel</h2>
      <p>
        Se tablet, telefono o monitor ... Quando si acquista qualsiasi
        dispositivo con una schermata, è necessario eseguire il test morto pixel
        e il test dei pixel bloccati dopo l'installazione.
      </p>
      <p>
        Prima noti un potenziale difetto nel prodotto che hai acquistato, Più
        facile sarà quello di elaborare il rendimento e lo scambio.Inoltre,
        questo il processo non richiede molto tempo, puoi facilmente testare i
        pixel morti e I pixel bloccati in pochi minuti.
      </p>
      <SvgDevices className="max-w-full px-3 md:px-10" />

      <p>
        È possibile avviare il test andando alla pagina corrispondente facendo
        clic su Il pulsante <code className="capitalize">Test morto pixel</code>{" "}
        qui sotto.Nella pagina che si apre, Esistono informazioni dettagliate
        sul pixel morto e sul test dei pixel così come lo strumento di test.
      </p>
      <div className="my-3 text-center">
        <Link
          to="/controlla-dead-pixel"
          style={{ textDecoration: "none" }}
          className="focus:outline-none text-white py-2.5 px-5 rounded-md bg-purple-500 hover:bg-purple-600 hover:shadow-lg text-base md:text-lg"
        >
          <span className="text-white capitalize">Test morto pixel</span>
        </Link>
      </div>
      <h2>
        Come riparare i pixel morti, correggere il problema dei pixel morti?
      </h2>
      <p>
        Un pixel morto significa che un pixel sullo schermo ha raggiunto la fine
        della sua vita.Dal momento che non richiede energia, è generalmente di
        colore nero.Non esiste una soluzione software per fissare il problema
        del pixel morto, di solito è richiesto il pannello e la sostituzione
        dello schermo per questo.Spingere verso il basso dolcemente può
        funzionare se ci sono pixel morti su schermi LCD causati dall'emissione
        di cristalli liquidi.
      </p>
      <SvgDeadPixelRepair className="max-w-full px-3 md:px-10" />

      <p>
        Nella pagina si aprirai facendo clic sul pulsante{" "}
        <code className="capitalize">Riparazione dei pixel morti</code> sotto, è
        spiegato passo dopo passo come rimuovere i tuoi pixel morti spingendo
        verso il basso dolcemente.
      </p>
      <div className="my-3 text-center">
        <Link
          to="/riparazione-pixel-morti"
          style={{ textDecoration: "none" }}
          className="focus:outline-none text-white py-2.5 px-5 rounded-md bg-purple-500 hover:bg-purple-600 hover:shadow-lg text-base md:text-lg"
        >
          <span className="text-white capitalize">
            Riparazione dei pixel morti
          </span>
        </Link>
      </div>
      <h2>
        Come riparare il pixel bloccato, fissare il problema del pixel bloccato?
      </h2>
      <p>
        I colori che non cambiano sullo schermo, specialmente se il loro colore
        non è nero o bianco, non significa sempre che siano pixel morti.I pixel
        che rimangono in un colore per un lungo periodo possono rimanere
        bloccati in colori come rosso, verde, blu.
      </p>
      <SvgStuckPixelRepair className="max-w-full px-3 md:px-10" />
      <p>
        C'è uno strumento di riparazione gratuito per i pixel bloccati nella
        pagina che si apre quando fai clic sul pulsante{" "}
        <code className="capitalize">Ripara pixel bloccati</code> in basso.
        Oltre a fornire informazioni sull'uso dello strumento di riparazione dei
        pixel bloccati, è anche possibile trovare informazioni su cosa sia il
        pixel bloccato e sulla differenza tra pixel bloccato e pixel morto nella
        pagina di riparazione dei pixel bloccati.
      </p>
      <div className="my-3 text-center">
        <Link
          to="/riparazione-pixel-bloccati"
          style={{ textDecoration: "none" }}
          className="focus:outline-none text-white py-2.5 px-5 rounded-md bg-purple-500 hover:bg-purple-600 hover:shadow-lg text-base md:text-lg"
        >
          <span className="text-white capitalize">Ripara pixel bloccati</span>
        </Link>
      </div>
    </>
  )
}
export default HomePage
